import React, { createContext, useEffect, useState } from 'react';
import LayoutNovo from '../../../components/LayoutNovo';
import Box from '@mui/material/Box';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Grid, Button, TableContainer } from '@mui/material';
import { useNavigate } from 'react-router';
import NumberBoxV4 from '../../../components/NumberBoxV4';
import { IconButton } from '@mui/material';
import Amvoxlogopng from '../../../assets/Amvoxlogopng.png';
import { Add } from '@mui/icons-material';
import { consultaProdutos } from '../../../services/produtos/produtos.service';
import ModalRegistro from './ModalRegistroPlanejamento';
import { useToast } from '../../../hooks/components/toast.hook';
import { consultaPlanejamento } from '../../../services/planejamentoSemanalProducao/planejamento.service';
import TabelaPlanejamento from './tabela';
import Loader from '../../../components/Loader';
import CachedIcon from '@mui/icons-material/Cached';

export function PlanejamentoProducao() {
  const [produtosLista, setProdutosLista] = useState([]);
  const [planejamentoProducaoLista, setPlanejamentoProducaoLista] = useState({
    produtos: [],
    executado: 0,
    planejado: 0,
  });
  const navigate = useNavigate();
  const [openModalRegistro, setOpenModalRegistro] = useState(false);
  const [loading, setLoading] = useState(true);

  const handleShowModalRegistro = () => {
    setOpenModalRegistro(!openModalRegistro);
  };

  const { addToast } = useToast();

  const handleFetchAtt = () => {
    consultaProdutos()
      .then((res) => {
        setProdutosLista(res);
      })
      .catch((_err) => {
        addToast({
          type: 'danger',
          title: 'Erro ao consultar produtos',
        });
      });
    consultaPlanejamento()
      .then((res) => {
        setPlanejamentoProducaoLista({
          produtos: res.produtos,
          executado: res.executado,
          planejado: res.planejado,
        });
        setLoading(false);
      })
      .catch((_err) => {
        addToast({
          type: 'danger',
          title: 'Erro ao consultar planejamento',
        });
      });
  };

  useEffect(() => {
    handleFetchAtt();
  }, []);

  const DataContext = createContext();

  return (
    <LayoutNovo style={{ zIndex: '-10' }}>
      <ModalRegistro
        openModal={openModalRegistro}
        handleClose={handleShowModalRegistro}
        produtosLista={produtosLista}
        handleAtt={handleFetchAtt}
      />

      <Box position={'relative'}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            pr: 2,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              pr: 2,
            }}
          >
            <div className="logoAmvox">
              <IconButton onClick={() => navigate(-1)}>
                <ChevronLeftIcon />
              </IconButton>
              <img src={Amvoxlogopng} alt="Amvox" className="imgAmvox"></img>
            </div>
            <div className="titulo">Planejamento Produção</div>
          </Box>
          <Grid item xs={6} sm={6} md={1}>
            <Button
              fullWidth
              variant="contained"
              onClick={handleShowModalRegistro}
              color="success"
              size="large"
              startIcon={<Add />}
              type="reset"
            >
              Registrar
            </Button>
          </Grid>
        </Box>

        <Grid container columnSpacing={2} px={1} py={1} gap={0.5}>
          <Grid item sx={6} md={4.5} xs={12} sm={12}>
            <NumberBoxV4
              colorPrimary={'#008000'}
              data={Number(planejamentoProducaoLista.planejado)}
              title={'Quantidade Planejada'}
              icon={'braçoMecanico'}
            />
          </Grid>
          <Grid item sx={6} md={4.5} xs={12} sm={12}>
            <NumberBoxV4
              colorPrimary={'#008000'}
              title={'Quantidade Executada'}
              icon={'done'}
              data={Number(planejamentoProducaoLista.executado)}
            />
          </Grid>
          <IconButton onClick={handleFetchAtt}>
            Atualizar
            <CachedIcon />
          </IconButton>
        </Grid>
      </Box>
      <Box
        margin={1}
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'center'}
        alignItems={'center'}
        gap={2}
      >
        {loading ? (
          <Loader />
        ) : (
          <TableContainer style={{ maxHeight: 500, overflowY: 'auto' }}>
            <TabelaPlanejamento
              listaPlanejamento={planejamentoProducaoLista.produtos}
              handleAtt={handleFetchAtt}
            />
          </TableContainer>
        )}
      </Box>
    </LayoutNovo>
  );
}

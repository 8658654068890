import {
  Box,
  FormControl,
  FormLabel,
  Button,
  Modal,
  TextField,
  Typography,
} from '@mui/material';
import ButtonCloseModal from '../../../../components/ButtonCloseModal';
import Select from 'react-select';
import { useState } from 'react';
import { useToast } from '../../../../hooks/components/toast.hook';
import {
  registrarPlanejamentoProducao,
} from '../../../../services/planejamentoSemanalProducao/planejamento.service';
import moment from 'moment/moment';

const style = {
  margin: 'auto',
  backgroundColor: 'background.paper',
  width: '95%',
  height: '95%',
  marginTop: '15px',
  padding: '35px',
  position: 'relative',
  display: 'flex',
  borderRadius:'24px',
  border: '2px solid #000',
  boxShadow: '0 3px 10px rgba(0, 0, 0, 0.5)'
};

export default function ModalRegistro({
  openModal,
  handleClose,
  produtosLista,
  handleAtt,
}) {
  const [listaPlanejamento, setListaPlanejamento] = useState([]);
  const [planejamentoSemanal, setPlanejamentoSemanal] = useState([]);
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [abrirPlanejamento, setAbrirPlanejamento] = useState(false);
  const [calculoPlanejamento, setCalculoPlanejamento] = useState(false);
  const [dataDaSemana, setDatasDaSemana] = useState('');
  const [isFilled, setIsFilled] = useState({
    quantidadeProdutos: false,
    produtoSelecionado: false,
    linhasDisponiveis: false,
    diasDisponiveis: false,
    horasDisponiveis: false,
  });
  const [isCalculoFilled, setIsCalculoFilled] = useState({
    semanaAno: false,
    observação: false,
    horasDisponiveisDias: false,
  });

  const { addToast } = useToast();

  const handleCloseModal = () => setOpen(false);

  const selectProdutosLista = produtosLista.map((item) => ({
    value: item.codigo,
    label: item.apelido,
  }));

  const handleAddPlanejamento = (value) => {
    if (
      isFilled.diasDisponiveis == false ||
      isFilled.horasDisponiveis == false ||
      isFilled.quantidadeProdutos == false ||
      isFilled.produtoSelecionado == false
    ) {
      addToast({
        type: 'danger',
        title: 'Preencha os campos corretamente',
        description: 'Os campos não podem ser enviados dessa maneira',
      });
    } else {
      setAbrirPlanejamento(true);
      setCalculoPlanejamento(false);
      setListaPlanejamento((old) => [...old, value]);
      setData({
        quantidadeProdutos: '',
        produtoSelecionado: {},
        op: '',
        observacao: '',
        pendencia: '',
        prioridade: '',
      });
      setIsFilled({
        quantidadeProdutos: false,
        produtoSelecionado: false,
        diasDisponiveis: true,
        horasDisponiveis: true,
        linhasDisponiveis: true,
      });
    }
  };

  const handleDeleteNota = () => {
    setListaPlanejamento((prev) => {
      const novaLista = [...prev];
      novaLista.pop();
      if (!novaLista.length == true) {
        setCalculoPlanejamento(false);
        setAbrirPlanejamento(false);
        data.diasDisponiveis = '';
        data.linhasDisponiveis = '';
        data.horasDisponiveis = '';
        data.horasDisponiveisDia = '';
        data.prioridade = '';
        data.observação = '';
      } else {
        setAbrirPlanejamento(true);
        setCalculoPlanejamento(false);
      }
      setIsFilled({
        diasDisponiveis: false,
        horasDisponiveis: false,
        linhasDisponiveis: false,
      });
      return novaLista;
    });
  };

  const handlePostPlanejamento = async (value) => {
    const codigo = value.map((item) => item.produtoSelecionado.value);
    const quantidade = value.map((item) => item.quantidadeProdutos);
    const observacao = value.map((item) => item.observacao);
    const op = value.map((item) => item.op);
    const pendencia = value.map((item) => item.pendencia);
    const prioridade = value.map((item) => item.prioridade);
    const diasDisponiveis = value[0]?.diasDisponiveis;
    const horasDisponiveis = value[0]?.horasDisponiveis;
    const horasDisponiveisDia = value[0]?.horasDisponiveisDia;
    const linhasDisponiveis = value[0]?.linhasDisponiveis;
    const periodoInicio = value[0]?.periodoInicio;
    const periodoFim = value[0]?.periodoFim;
    const semanaAno = value[0]?.semanaAno;
    const observação = value[0]?.observação;

    let result = [{}];
    codigo.forEach((item, index) => {
      result.push({
        codigo: `${item}`,
        quantidade: `${quantidade[index]}`,
        observacao: `${observacao[index]}`,
        op: `${op[index]}`,
        pendencia: `${pendencia[index]}`,
        prioridade: `${prioridade[index]}`,
      });
    });
    result.shift();
    const resultado = {
      semanaAno: semanaAno.substring(6, 8),
      periodoInicio: periodoInicio,
      periodoFim: periodoFim,
      horasDisponiveis: horasDisponiveis,
      horasDisponiveisDia: horasDisponiveisDia,
      diasDisponiveis: diasDisponiveis,
      linhasDisponiveis: linhasDisponiveis,
      observação: observação,
      produtos: result,
    };
    registrarPlanejamentoProducao(resultado)
      .then((response) => {
        const { message, listaProdutosNaoEncontrados } = response.value;
        if (
          listaProdutosNaoEncontrados &&
          listaProdutosNaoEncontrados.length > 0
        ) {
          const produtosNaoEncontrados = `Produtos não encontrados: ${listaProdutosNaoEncontrados.join(
            ', '
          )}`;
          addToast({
            type: 'warning',
            title: 'Atenção!',
            description: `${message}. ${produtosNaoEncontrados}`,
          });
        } else {
          addToast({
            type: 'success',
            title: 'Sucesso!',
            description: 'Planejamento registrado com sucesso.',
          });
        }
        setPlanejamentoSemanal(response.result);
        setCalculoPlanejamento(true);
        handleCloseModal(true);
        handleClose(true);
        handleAtt();
      })
      .catch((error) => {
        const errorMessage =
          error.response?.data?.message || 'Erro ao registrar planejamento';
        addToast({
          type: 'danger',
          title: 'Erro!',
          description: errorMessage,
        });
      });
  };

  const handleGetDiasDaSemana = (value) => {
    const [ano, semana] = value.split('-W');

    const comecoSemana = moment().year(ano).week(semana).startOf('isoWeek');

    const fimSemana = moment().year(ano).week(semana).endOf('isoWeek');

    const diasDaSemana = [];

    for (let i = 0; i < 5; i++) {
      const dia = comecoSemana.clone().add(i, 'days');
      diasDaSemana.push({
        data: dia.format('YYYY-MM-DD'),
      });
    }

    const inicioSemanaFormatada = comecoSemana.format('YYYY-MM-DD');
    const fimSemanaFormatada = fimSemana.format('YYYY-MM-DD');

    setData((data) => ({
      ...data,
      semanaAno: value,
      periodoInicio: inicioSemanaFormatada,
      periodoFim: fimSemanaFormatada,
    }));
    setIsCalculoFilled((prevFilledState) => ({
      ...prevFilledState,
      horasDisponiveisDias: true,
    }));

    setDatasDaSemana(diasDaSemana);
  };


  return (
    <div>
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
        BackdropProps={{
          style: {
            backdropFilter: 'blur(1px)', 
          },
        }}
      >
        <Box sx={{ ...style }}>
          <ButtonCloseModal
            sx={{ position: 'absolute', top: 0 }}
            onClick={handleClose}
          />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-around',
              flexWrap: 'wrap',
              padding: '15px',
              height: '100%',
            }}
          >
            <Typography
              variant="h6"
              component={'p'}
              textAlign={'center'}
              color={'#000'}
            >
              Planejamento Semanal
            </Typography>
            <FormControl sx={{ width: '300px', padding: '10px' }}>
              <FormLabel>Semana do ano</FormLabel>
              <TextField
                type="week"
                id="outlined-basic"
                color="success"
                variant="outlined"
                required
                onChange={(e) => {
                  handleGetDiasDaSemana(e.target.value);
                }}
              />
            </FormControl>
            <FormControl sx={{ width: '20rem', padding: '10px' }}>
              <FormLabel sx={{ marginBottom: '5px' }}>
                Selecione o produto para planejar
              </FormLabel>
              <Select
                labelId="demo-simple-select-autowidth-label"
                id="demo-simple-select-autowidth"
                placeholder={'Produtos'}
                options={selectProdutosLista}
                onChange={(e) => {
                  setData({ ...data, produtoSelecionado: e });
                  setIsFilled({ ...isFilled, produtoSelecionado: true });
                }}
                required
                value={data.produtoSelecionado}
              />
            </FormControl>
            <FormControl sx={{ width: '12rem', padding: '10px' }}>
              <FormLabel sx={{ marginBottom: '5px' }}>
                Dias disponíveis
              </FormLabel>
              <TextField
                type="number"
                placeholder={'5'}
                variant="outlined"
                id="outlined-multiline-static"
                name="patrimonio"
                rows={1}
                required
                onChange={(e) => {
                  setData({ ...data, diasDisponiveis: e.target.value });
                  setIsFilled({ ...isFilled, diasDisponiveis: true });
                }}
                value={data.diasDisponiveis}
              />
            </FormControl>
            <FormControl sx={{ width: '12rem', padding: '10px' }}>
              <FormLabel sx={{ marginBottom: '5px' }}>
                Horas disponíveis semana
              </FormLabel>
              <TextField
                type="number"
                placeholder={'44'}
                variant="outlined"
                id="outlined-multiline-static"
                name="patrimonio"
                rows={1}
                required
                onChange={(e) => {
                  setData({ ...data, horasDisponiveis: e.target.value });
                  setIsFilled({
                    ...isFilled,
                    horasDisponiveis: true,
                  });
                }}
                value={data.horasDisponiveis}
              />
            </FormControl>
            <FormControl sx={{ width: '12rem', padding: '10px' }}>
              <FormLabel sx={{ marginBottom: '5px' }}>
                Horas disponíveis dia
              </FormLabel>
              <TextField
                type="number"
                placeholder={'8'}
                variant="outlined"
                id="outlined-multiline-static"
                name="patrimonio"
                rows={1}
                required
                onChange={(e) => {
                  setData({ ...data, horasDisponiveisDia: e.target.value });
                  setIsFilled({
                    ...isFilled,
                    horasDisponiveis: true,
                  });
                }}
                value={data.horasDisponiveisDia}
              />
            </FormControl>
            <FormControl sx={{ width: '12rem', padding: '10px' }}>
              <FormLabel sx={{ marginBottom: '5px' }}>
                Linhas disponíveis
              </FormLabel>
              <TextField
                type="number"
                variant="outlined"
                id="outlined-multiline-static"
                placeholder={'8'}
                rows={1}
                required
                onChange={(e) => {
                  setData({ ...data, linhasDisponiveis: e.target.value });
                  setIsFilled({
                    ...isFilled,
                    linhasDisponiveis: true,
                  });
                }}
                value={data.linhasDisponiveis}
              />
            </FormControl>
            <FormControl sx={{ width: '300px', padding: '10px' }}>
              <FormLabel sx={{ marginBottom: '5px' }}>
                Quantidade de produtos a ser feita
              </FormLabel>
              <TextField
                type="number"
                placeholder={'Quantidade a ser produzida'}
                variant="outlined"
                id="outlined-multiline-static"
                name="patrimonio"
                rows={1}
                required
                onChange={(e) => {
                  setData({ ...data, quantidadeProdutos: e.target.value });
                  setIsFilled({
                    ...isFilled,
                    quantidadeProdutos: true,
                  });
                }}
                value={data.quantidadeProdutos}
              />
            </FormControl>
            <FormControl sx={{ width: '300px', padding: '10px' }}>
              <FormLabel>Observação do produto</FormLabel>
              <TextField
                type="text"
                placeholder={'Observação do produto'}
                variant="outlined"
                id="outlined-multiline-static"
                rows={1}
                required
                onChange={(e) => {
                  setData({ ...data, observacao: e.target.value });
                  setIsFilled({
                    ...isFilled,
                    quantidadeProdutos: true,
                  });
                }}
                value={data.observacao}
              />
            </FormControl>
            <FormControl sx={{ width: '300px', padding: '10px' }}>
              <FormLabel>Pendência</FormLabel>
              <TextField
                type="text"
                placeholder={'Pendência'}
                variant="outlined"
                id="outlined-multiline-static"
                rows={1}
                required
                onChange={(e) => {
                  setData({ ...data, pendencia: e.target.value });
                  setIsFilled({
                    ...isFilled,
                    quantidadeProdutos: true,
                  });
                }}
                value={data.pendencia}
              />
            </FormControl>

            <FormControl sx={{ width: '300px', padding: '10px' }}>
              <FormLabel>Número OP</FormLabel>
              <TextField
                type="text"
                placeholder={'Número OP'}
                variant="outlined"
                id="outlined-multiline-static"
                rows={1}
                required
                onChange={(e) => {
                  setData({ ...data, op: e.target.value });
                  setIsFilled({
                    ...isFilled,
                    quantidadeProdutos: true,
                  });
                }}
                value={data.op}
              />
            </FormControl>
            <FormControl sx={{ width: '12rem', padding: '10px' }}>
              <FormLabel sx={{ marginBottom: '5px' }}>Prioridade</FormLabel>
              <TextField
                type="number"
                placeholder={'Prioridade'}
                variant="outlined"
                id="outlined-multiline-static"
                name="patrimonio"
                rows={1}
                required
                onChange={(e) => {
                  setData({ ...data, prioridade: e.target.value });
                  setIsFilled({
                    ...isFilled,
                    horasDisponiveis: true,
                  });
                }}
                value={data.prioridade}
              />
            </FormControl>
            <FormControl sx={{ width: '300px', padding: '10px' }}>
              <FormLabel>Observação do planejamento</FormLabel>
              <TextField
                type="text"
                placeholder={'Observação do planejamento'}
                variant="outlined"
                id="outlined-multiline-static"
                rows={1}
                required
                onChange={(e) => {
                  setData({ ...data, observação: e.target.value });
                  setIsFilled({
                    ...isFilled,
                    quantidadeProdutos: true,
                  });
                }}
                value={data.observação}
              />
            </FormControl>

            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '20px',
                alignItems: 'center',
              }}
            >
              <Button
                onClick={() => handleDeleteNota()}
                type="submit"
                size="small"
                color="error"
                variant="contained"
              >
                Deletar planejamento
              </Button>

              <Button
                onClick={() => handleAddPlanejamento(data)}
                type="submit"
                size="small"
                color="primary"
                variant="contained"
              >
                Adicionar mais produtos
              </Button>

              <Button
                onClick={() => handlePostPlanejamento(listaPlanejamento)}
                type="submit"
                size="small"
                color="success"
                variant="contained"
              >
                Confirmar Planejamento
              </Button>
            </div>
          </Box>
          {abrirPlanejamento ? (
            <section
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '30%',
                overflow: 'auto',
                overflowInline: 'auto',
                marginLeft: '200px',
                borderRadius:'24px',
                border: '1.5px solid #000',
                boxShadow: '0 1px 3px rgba(0, 0, 0, 0.5)',
                background: 'radial-gradient(circle, rgba(221,174,238,1) 0%, rgba(148,187,233,1) 100%)',
              }}
            >
              <div
                style={{
                  borderRadius: '20px',
                  margin: '10px',
                  padding: '8px',
                  
                }}
              >
                <section>
                  <h1 style={{ alignItems: 'center', justifyContent: 'center' }}>Planejamento</h1>
                  <div
                    style={{

                      border: '1px solid #000000',
                      borderRadius:'8px'
                    }}
                  >
                    <div style={{ padding: '8px', display: 'flex', flexDirection:'column' }}>
                      <b>Semana do ano: {listaPlanejamento[0]?.semanaAno.substring(6, 8)}</b>
                      <b>
                        Linhas disponíves:{' '}
                        {listaPlanejamento[0]?.linhasDisponiveis}
                      </b>
                      <b>
                        Dias disponíveis: {listaPlanejamento[0]?.diasDisponiveis}
                      </b>
                      <b>
                        Horas disponíveis: {' '}
                        {listaPlanejamento[0]?.horasDisponiveis}
                      </b>
                    </div>

                    {listaPlanejamento.map((item) => (
                      <div
                        style={{
                          borderTop: '3px solid grey',
                          padding: '4px',
                        }}
                      >
                        <ul>
                          <li>Produto: {item.produtoSelecionado.label}</li>
                          <li>
                            Código do Produto: {item.produtoSelecionado.value}
                          </li>
                          <li>
                            Quantidade a ser produzida:{' '}
                            {item.quantidadeProdutos}
                          </li>
                          <li>Número OP: {item.op}</li>
                          <li>Pendência: {item.pendencia}</li>
                          <li>Prioridade: {item.prioridade}</li>
                        </ul>
                      </div>
                    ))}
                  </div>
                </section>
              </div>
            </section>
          ) : (
            <div></div>
          )}
        </Box>
      </Modal>
    </div>
  );
}

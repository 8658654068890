export default [
  {
    nome: 'Setor',
    valor: 0,
  },
  {
    nome: 'Comercial',
    valor: 1,
  },
  {
    nome: 'Diretoria',
    valor: 2,
  },
  {
    nome: 'Estoque',
    valor:3,
  },
  {
    nome: 'Financeiro',
    valor: 4,
  },
  {
    nome: 'Fiscal',
    valor: 5,
  },
  {
    nome: 'Gestão Talentos',
    valor: 6,
  },
  {
    nome: 'Inteligencia',
    valor: 7,
  },
  {
    nome: 'Importação',
    valor: 8,
  },
  {
    nome: 'Logistica',
    valor: 9,
  },
  {
    nome: 'Produção',
    valor: 10,
  },
  {
    nome: 'Pos-Vendas',
    valor: 11,
  },
  {
    nome: 'Promotoras',
    valor: 12,
  },
  {
    nome: 'Tecnicos',
    valor: 13,
  },
  {
    nome: 'NoAccess',
    valor: 14,
  },
  {
    nome: 'Secretariado',
    valor: 15,
  },
  {
    nome: 'Portaria',
    valor: 16,
  },
  {
    nome: 'Inovação',
    valor: 17,
  },
  {
    nome: 'ComprasInt',
    valor: 18,
  },
  {
    nome: 'ComprasNac',
    valor: 19,
  },
  {
    nome: 'Qualidade',
    valor: 20,
  },
  {
    nome: 'Limpeza',
    valor: 21,
  },
  {
    nome: 'Qcompra',
    valor: 22,
  },
  {
    nome: 'Tec. de Segurança',
    valor: 23,
  },
  {
    nome: 'Recepção',
    valor: 24,
  },
  {
    nome: 'Marketing',
    valor: 25,
  },
  {
    nome: 'Controller',
    valor: 26,
  },
];

export const setorSelect = [
  {
    nome: 'Setor',
    valor: 0,
  },
  {
    nome: 'Comercial',
    valor: 1,
  },
  {
    nome: 'Diretoria',
    valor: 2,
  },
  {
    nome: 'Estoque',
    valor:3,
  },
  {
    nome: 'Financeiro',
    valor: 4,
  },
  {
    nome: 'Fiscal',
    valor: 5,
  },
  {
    nome: 'Gestão Talentos',
    valor: 6,
  },
  {
    nome: 'Inteligencia',
    valor: 7,
  },
  {
    nome: 'Importação',
    valor: 8,
  },
  {
    nome: 'Logistica',
    valor: 9,
  },
  {
    nome: 'Produção',
    valor: 10,
  },
  {
    nome: 'Pos-Vendas',
    valor: 11,
  },
  {
    nome: 'Promotoras',
    valor: 12,
  },
  {
    nome: 'Tecnicos',
    valor: 13,
  },
  {
    nome: 'Secretariado',
    valor: 15,
  },
  {
    nome: 'Portaria',
    valor: 16,
  },
  {
    nome: 'Inovação',
    valor: 17,
  },
  {
    nome: 'ComprasInt',
    valor: 18,
  },
  {
    nome: 'ComprasNac',
    valor: 19,
  },
  {
    nome: 'Qualidade',
    valor: 20,
  },
  {
    nome: 'Limpeza',
    valor: 21,
  },
  {
    nome: 'Qcompra',
    valor: 22,
  },
  {
    nome: 'Tec. de Segurança',
    valor: 23,
  },
  {
    nome: 'Recepção',
    valor: 24,
  },
  {
    nome: 'Marketing',
    valor: 25,
  },
  {
    nome: 'Controller',
    valor: 26,
  },
];
import React, { useEffect, useMemo, useState } from 'react';
import axios from 'axios';

import { MaterialReactTable } from 'material-react-table';

import { MRT_Localization_PT_BR } from 'material-react-table/locales/pt-BR';

const Table = (posts) => {
  const columns = useMemo(
    () => [
      {
        accessorKey: 'codigo',
        header: 'Codigo',
        size: 150,
      },
      {
        accessorKey: 'produto',
        header: 'Produto',
        size: 150,
      },
      {
        accessorKey: 'metaHoraProducao',
        header: 'Meta Hora',
        size: 150,
      },
      {
        accessorKey: 'quantidadeOperadores',
        header: 'Quantidade Operadores',
        size: 150,
      },
      {
        accessorKey: 'taxaAproveitamento',
        header: 'Taxa Aproveitamento',
        size: 150,
      },
    ],
    []
  );

  return (
    <MaterialReactTable
      columns={columns}
      data={posts.posts}
      localization={MRT_Localization_PT_BR}
    />
  );
};

export default Table;

import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import SendIcon from '@mui/icons-material/Send';
import DeleteIcon from '@mui/icons-material/Delete';
import debounce from '../../../../utils/debounce';

import useUsuarioLocal from '../../../../hooks/usuarioLocal.hook';
import { FormControl, TextField } from '@mui/material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  justifyContent: 'center',
  alignItems: 'center',
  p: 4,
};

const newRegister = {
  codigo: '',
  metaHora_Prod: 0,
  qtdOperadores: 0,
  userLog: '',
};

export default function ModalRegistro({ handleSubmit, GetDados }) {
  const [formData, setFormData] = React.useState(newRegister);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { email } = useUsuarioLocal();

  const handleSubmitFormData = () => {
    handleSubmit(formData);
    setFormData(newRegister);
    debounce(() => {
      GetDados();
    }, 3000);
    handleClose();
  };

  return (
    <div>
      <Button
        onClick={handleOpen}
        fullWidth
        variant="contained"
        color="success"
        size="large"
        startIcon={<PlaylistAddIcon />}
      >
        Registrar
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Registrar
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Usuário: {email}
          </Typography>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignContent: 'center',
              justifyContent: 'center',
              mb: 2,
            }}
          >
            <FormControl sx={{ display: 'flex', my: 2 }}>
              <TextField
                id="outlined-basic"
                label="Código"
                variant="outlined"
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    codigo: e.target.value,
                    userLog: email,
                  });
                }}
              />
            </FormControl>
            <FormControl sx={{ display: 'flex', mb: 2 }}>
              <TextField
                id="outlined-basic"
                label="Meta Hora"
                variant="outlined"
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    metaHora_Prod: e.target.value,
                  });
                }}
              />
            </FormControl>
            <FormControl sx={{ display: 'flex', mb: 2 }}>
              <TextField
                id="outlined-basic"
                label="Quantidade Operadores"
                variant="outlined"
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    qtdOperadores: e.target.value,
                  });
                }}
              />
            </FormControl>
          </Box>

          <Box sx={{ display: 'flex', gap: 2, paddingY: 1 }} fullWidth>
            <Button
              variant="contained"
              color="success"
              endIcon={<SendIcon />}
              fullWidth
              type="submit"
              onClick={(e) => handleSubmitFormData(e)}
            >
              Enviar
            </Button>
            <Button
              variant="outlined"
              color="error"
              endIcon={<DeleteIcon />}
              fullWidth
              type="reset"
              onClick={handleClose}
            >
              Cancelar
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}

import React, { useEffect, useState } from 'react';
import './styles.css';
import { useNavigate } from 'react-router';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import TitlePageV3 from '../../components/TitlePageV3';
import {
  Box,
  Button,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import SidebarNovo from '../../components/LayoutNovo/SidebarNovo';
import Amvoxlogopng from '../../assets/Amvoxlogopng.png';
import ModalRegistro from './ModalRegistro';
import ModalHistorico from './ModalHistorico';
import xVermelho from '../../assets/xVermelho.svg';
import TabelaKpi from './TabelaKpi';
import GraficoInventario from './GraficoInventario';
import GraficoRadialBar from './GraficoDonutInventario';
import {
  GetEstoqueInventarioCardsMes,
  GetEstoqueInventarioGraficoBarras,
  GetEstoqueInventarioHistorico,
  GetEstoqueInventarioPrincipal,
} from '../../services/inventario.service';
import CardInventarioMes from './CardInventarioMes';
import CardInventarioAno from './CardInventarioAno';

const meses = [
  { id: 1, nome: 'Jan' },
  { id: 2, nome: 'Fev' },
  { id: 3, nome: 'Mar' },
  { id: 4, nome: 'Abr' },
  { id: 5, nome: 'Mai' },
  { id: 6, nome: 'Jun' },
  { id: 7, nome: 'Jul' },
  { id: 8, nome: 'Ago' },
  { id: 9, nome: 'Set' },
  { id: 10, nome: 'Out' },
  { id: 11, nome: 'Nov' },
  { id: 12, nome: 'Dez' },
];

export default function KPIInventario() {
  const baseYear = new Date().getFullYear();
  const years = [baseYear - 1, baseYear];
  const [filtro, setFiltro] = useState({
    ano: baseYear,
    mes: new Date().getMonth() + 1,
  });
  const [selectedMes, setSelectedMes] = useState(null);
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [dataHistorico, setDataHistorico] = useState([]);
  const [dataCard, setDataCard] = useState([]);
  const [dataGrafico, setDataGrafico] = useState([]);

  const mesAtual = new Date().getMonth() + 1;
  const mesSelecionadoId = filtro.mes === 0 ? mesAtual : filtro.mes;
  const mesSelecionado = meses.find((mes) => mes.id === mesSelecionadoId);

  const handleYearChange = (event) => {
    const year = parseInt(event.target.value, 10);
    setFiltro({ ano: year, mes: filtro.mes });
  };

  const handleFetch = () => {
    GetEstoqueInventarioPrincipal(filtro).then((res) => {
      setData(res);
    });
    GetEstoqueInventarioHistorico().then((res) => {
      setDataHistorico(res.data);
    });
    GetEstoqueInventarioCardsMes(filtro).then((res) => {
      setDataCard(res.data);
    });
    GetEstoqueInventarioGraficoBarras(filtro).then((res) => {
      setDataGrafico(res.data);
    });
  };

  useEffect(() => {
    handleFetch();
    if (filtro.mes) {
      setSelectedMes(filtro.mes);
    }
  }, [filtro]);

  const handleSelected = (id) => {
    setSelectedMes((prevSelectedMes) => (prevSelectedMes === id ? null : id));
  };

  const handleClear = () => {
    setFiltro({
      ano: baseYear,
      mes: setSelectedMes(null),
    });
  };

  return (
    <>
      <Box className="Principal">
        <Box className="sidebar">
          <SidebarNovo />
        </Box>
        <Box
          position={'relative'}
          sx={{ backgroundColor: '#f3f4f6' }}
          gap={2}
          padding={'50px'}
        >
          <Box
            sx={{
              justifyContent: 'space-between',
              alignItems: 'center',
              display: 'flex',
            }}
          >
            <IconButton onClick={() => navigate(-1)}>
              <ChevronLeftIcon />
            </IconButton>
            <img src={Amvoxlogopng} alt="Amvox" className="imgAmvox"></img>
            <Box
              sx={{
                display: 'inline-table',
                justifyContent: 'space-between',
                alignItems: 'center',
                display: 'inline-flex',
                width: '100%',
              }}
            >
              <TitlePageV3 title={'KPI de Inventário'} />
            </Box>
          </Box>
          <Box className="boxGeral">
            <Box className="boxFiltro">
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '10px',
                  }}
                ></Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  width: '100%',
                  gap: '16px',
                }}
              >
                <ModalHistorico dataHistorico={dataHistorico} />
                <ModalRegistro />
              </Box>
              <Box className="boxConsultar">
                <Box className="boxSelects">
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: { xs: 'column', sm: 'row' },
                      width: '100%',
                      gap: '24px',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        width: '300px',
                        height: '32px',
                      }}
                    >
                      <TextField
                        select
                        fullWidth
                        sx={{
                          display: 'flex',
                          width: '100%',
                          height: '32px',
                          '& .MuiInputBase-root': {
                            height: '32px',
                            borderRadius: '8px',
                            '&:focus-within': {
                              border: '1px solid rgba(0, 0, 0, 0.10)',
                            },
                          },
                        }}
                        defaultValue={filtro.ano}
                        inputProps={{
                          style: {
                            height: '32px',
                            width: '100%',
                          },
                        }}
                        value={filtro.ano}
                        onChange={handleYearChange}
                      >
                        {years.map((year) => (
                          <MenuItem key={year} value={year}>
                            {year}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Box>

                    <Box sx={{ display: 'flex' }}>
                      {meses.map((item) => (
                        <Button
                          key={item.id}
                          variant="contained"
                          onClick={() => {
                            handleSelected(item.id);
                            setFiltro((prevFiltro) => ({
                              ...prevFiltro,
                              mes: prevFiltro.mes === item.id ? 0 : item.id,
                            }));
                          }}
                          sx={{
                            display: 'flex',
                            height: '32px',
                            fontFamily: 'Poppins, Poppins Bold, sans-serif',
                            textTransform: 'capitalize',
                            borderRadius: '8px',
                            border: '1px solid rgba(0, 0, 0, 0.10)',
                            boxShadow: '0px 1px 1px 0px rgba(0, 0, 0, 0.25)',
                            transition: 'background-color 0.2s ease-in-out',
                            marginRight: '8px',
                            backgroundColor:
                              selectedMes === item.id ? '#a00' : '#FFF',
                            color: selectedMes === item.id ? '#fff' : 'black',
                            '&:hover': {
                              backgroundColor:
                                selectedMes === item.id ? '#a00' : 'lightGray',
                            },
                          }}
                        >
                          {item.nome}
                        </Button>
                      ))}
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        width: '100%',
                        ml: '-50px',
                      }}
                    >
                      <Button
                        onClick={handleClear}
                        variant="outlined"
                        sx={{
                          color: '#6E6E6E',
                          border: '2px solid #CCCCCC',
                          height: '32px',
                          borderRadius: '8px',
                          transition:
                            'background-color 0.5s ease, transform 0.3s ease-in-out',
                          border: '2px solid #CCCCCC',
                          '&:hover': {
                            transform: 'scale(1.1)',
                            transition:
                              'background-color 0.5s ease, transform 0.3s ease-in-out',
                            border: '2px solid #CCCCCC',
                          },
                        }}
                        startIcon={<HighlightOffIcon />}
                      >
                        Limpar filtro
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                mt: '16px',
                display: 'flex',
                maxHeight: '303px',
                width: '100%',
              }}
            >
              <TabelaKpi dataTabela={data.data} />
            </Box>

            <Box sx={{ mt: '16px', gap: '16px', display: 'flex' }}>
              <CardInventarioMes dataCard={dataCard} title={filtro} />
              <CardInventarioAno dataCard={dataCard} title={filtro.ano} />
              <Box className="boxGraficoRadial">
                <Typography
                sx = {{fontWeight: 'bold', justifyContent: 'flex-start', display: 'flex'}}
                >
                  Inventário - Geral{' '}
                  {mesSelecionado ? mesSelecionado.nome : 'Mês inválido'}{' '}
                  {filtro.ano}
                </Typography>
                <GraficoRadialBar dataCard={dataCard} />
              </Box>
            </Box>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                padding: '24px',
                width: '100%',
                mt: '16px',
                borderRadius: '18px',
                boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.25)',
                bgcolor: '#fff',
              }}
            >
              <Typography>Inventário Armazéns Geral</Typography>
              <GraficoInventario data={dataGrafico} />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          marginTop: '25px',
          opacity: '85%',
          flexDirection: 'column',
          alignItems: 'center',
          backgroundColor: '#000000',
        }}
      >
        {' '}
        <footer className="footerPagePrincipal">
          <img src={xVermelho} alt="Amvox"></img>
          Copyright &copy; {new Date().getFullYear()} Amvox. Todos os direitos
          reservados.
          <br />
        </footer>
      </Box>
    </>
  );
}

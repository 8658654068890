import React from 'react';

import { useState, useEffect } from 'react';

import LayoutNovo from '../../components/LayoutNovo';
import { Grid, Box } from '@mui/material';
import TitlePage from '../../components/TitlePage';

import BackupTableIcon from '@mui/icons-material/BackupTable';

import { useToast } from '../../hooks/components/toast.hook';

import Table from './components/Table';
import ModalRegistro from './components/Modal';
import axios from 'axios';
import { BuscarProdutoMetahoraGet, ProdutoMetahoraPost } from '../../services/tabelaMestra.service';

export default function TabelaMestra() {
  const [posts, setPosts] = useState([]);

  const { addToast } = useToast();

  const SubmitMetaHora = async (body) => {
    ProdutoMetahoraPost(body)
      .then(() => {
        addToast({
          type: 'success',
          description: 'Cadastrado com sucesso',
        });
      })
      .catch(() => {
        addToast({
          type: 'danger',
          description: 'Error ao cadastrar',
        });
      });
  };

  function GetDados() {
    BuscarProdutoMetahoraGet()
      .then((response) => {
        setPosts(response);
      })
      .catch(() => {
        addToast({
          type: 'danger',
          description: 'Error ao buscar tabela',
        });
      });
  }

  useEffect(() => {
    GetDados();
  }, []);

  return (
    <LayoutNovo setorColor={'producao'}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          pr: 2,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            zIndex: 1,
            pr: 2,
          }}
        >
          <TitlePage
            title={'Tabela Mestra'}
            iconChildren={<BackupTableIcon fontSize="large" />}
          />
        </Box>
        <Grid item xs={6} sm={6} md={1}>
          <ModalRegistro handleSubmit={SubmitMetaHora} GetDados={GetDados} />
        </Grid>
      </Box>
      <Table posts={posts} />
    </LayoutNovo>
  );
}

import React from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import formatDateTotvs from '../../../utils/formatDataTotvs';
import CircularProgressWithColor from '../../../components/CircularProgressWithColor';
import RegistrarQuantidadeProduzida from './ModalQTProduzido';
import { useEffect } from 'react';

function Row(props, handleAtt) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);


  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell sx={{ width: '10px' }}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="right">{row.semana_Ano}</TableCell>
        <TableCell component="th" scope="row">
          {formatDateTotvs(row.periodoInicio)}
        </TableCell>
        <TableCell component="th" scope="row">
          {formatDateTotvs(row.periodoFim)}
        </TableCell>
        <TableCell align="right">{row.quantidadeProgramacao}</TableCell>
        <TableCell align="right">{row.horasNecessarias}</TableCell>
        <TableCell align="right">{row.horasdisponiveis}</TableCell>
        <TableCell align="right">{row.capacidadeutilizada}</TableCell>
        <TableCell align="right">{row.horasDisponiveisDiariamente}</TableCell>
        <TableCell align="right">{row.linhasDisponiveis}</TableCell>
        <TableCell align="right">{row.diasDisponiveis}</TableCell>
        <TableCell align="right">{row.quantidadeProduzida}</TableCell>
        <TableCell align="right">{row.observacao}</TableCell>
        <TableCell align="right">
          <CircularProgressWithColor
            sx={{ width: '250px', Height: '250px' }}
            value={row.porcentagemExecutado}
            cor={'#219C90'}
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Produtos
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ fontWeight: 'bold' }}>Código</TableCell>
                    <TableCell style={{ fontWeight: 'bold' }}>
                      Descrição
                    </TableCell>
                    <TableCell style={{ fontWeight: 'bold' }} align="right">
                      Peças/Hora
                    </TableCell>
                    <TableCell style={{ fontWeight: 'bold' }} align="right">
                      Total horas necessárias
                    </TableCell>
                    <TableCell style={{ fontWeight: 'bold' }} align="right">
                      Prioridade
                    </TableCell>
                    <TableCell style={{ fontWeight: 'bold' }} align="center">
                      Qtde realizada
                    </TableCell>
                    <TableCell style={{ fontWeight: 'bold' }} align="right">
                      Nº OP
                    </TableCell>
                    <TableCell style={{ fontWeight: 'bold' }} align="right">
                      Observações
                    </TableCell>
                    <TableCell style={{ fontWeight: 'bold' }} align="right">
                      Pendência
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.detalhes.map((detalhes) => (
                    <TableRow>
                      <TableCell align="right">{detalhes.codigo}</TableCell>
                      <TableCell align="right" style={{ whiteSpace: 'nowrap' }}>
                        {detalhes.descricaoProduto}
                      </TableCell>
                      <TableCell align="right">{detalhes.metaHora}</TableCell>
                      <TableCell align="right">
                        {detalhes.horasNecessarias}
                      </TableCell>
                      <TableCell align="right">{detalhes.prioridade}</TableCell>
                      <TableCell align="center">
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignContent: 'center',
                            flexDirection: 'column'
                          }}
                        >
                          {detalhes.qtdRealizada}
                          <RegistrarQuantidadeProduzida
                            value={detalhes.qtdRealizada}
                            id={detalhes.id_plan}
                            codigo={detalhes.codigo}
                            observacao={detalhes.observacao}
                            handlePut={handleAtt}
                          />
                        </Box>
                      </TableCell>
                      <TableCell align="right">{detalhes.op}</TableCell>
                      <TableCell align="right">{detalhes.observacao}</TableCell>
                      <TableCell align="right">{detalhes.pendencia}</TableCell>
                    </TableRow>
                  ))}

                  <TableCell
                    align="right"
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      borderBottom: 'unset',
                    }}
                  ></TableCell>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

export default function TabelaPlanejamento({ listaPlanejamento, handleAtt }) {

  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell style={{ fontWeight: 'bold' }} align="right">
              Semanas
            </TableCell>
            <TableCell style={{ fontWeight: 'bold' }} align="right">
              Periodo Início
            </TableCell>
            <TableCell style={{ fontWeight: 'bold' }} align="right">
              Periodo Fim
            </TableCell>
            <TableCell style={{ fontWeight: 'bold' }} align="right">
              Quantidade Programada
            </TableCell>
            <TableCell style={{ fontWeight: 'bold' }} align="right">
              Hrs Prod. Necessarias
            </TableCell>
            <TableCell style={{ fontWeight: 'bold' }} align="right">
              Hrs. Disp
            </TableCell>
            <TableCell style={{ fontWeight: 'bold' }} align="right">
              Capacidade Utilizada
            </TableCell>
            <TableCell style={{ fontWeight: 'bold' }} align="right">
              Horas Disponíveis/Dia
            </TableCell>
            <TableCell style={{ fontWeight: 'bold' }} align="right">
              Linhas Disponíveis
            </TableCell>
            <TableCell style={{ fontWeight: 'bold' }} align="right">
              Dias Disponíveis
            </TableCell>
            <TableCell style={{ fontWeight: 'bold' }} align="right">
              Quantidade Produzida
            </TableCell>
            <TableCell style={{ fontWeight: 'bold' }} align="right">
              Observação
            </TableCell>
            <TableCell style={{ fontWeight: 'bold' }} align="right">
              Porcentagem(%)
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {listaPlanejamento.map((row) => (
            <Row key={row.id} row={row} handleAtt={handleAtt} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

import { apiFabrica_operacao } from './apis';

export const BuscarProdutoMetahoraGet = async () => {
  try {
    const result = await apiFabrica_operacao.get(`ProdutosMetaHora`);

    return Promise.resolve(result.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const ProdutoMetahoraPost = async (body) => {
  try {
    const result = await apiFabrica_operacao.post(`ProdutosMetaHora`, body);
    return Promise.resolve(result.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

import * as React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';

function TabelaKpi({ dataTabela }) {
  const meses = Array.isArray(dataTabela) ? dataTabela : [];

  const nomesMeses = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ];

  const formatPercentage = (value) => `${value}%`;

  const renderRows = (mes) => {
    const armazemMap = {};

    [30, 40, 50, 51, 52].forEach((armazem) => {
      armazemMap[armazem] = {
        qtdContada: 0,
        acuracidade: '0,00%',
        hasData: false,
      };
    });

    mes.resultados.forEach((row) => {
      armazemMap[row.armazem] = {
        qtdContada: row.qtdContada,
        acuracidade: row.acuracidade,
        hasData: true,
      };
    });

    const acuracidadeGeral = parseFloat(mes.acuracidadeGeral.replace(',', '.'));

    return (
      <TableRow key={mes.mes}>
        <TableCell component="th" scope="row">
          {nomesMeses[mes.mes - 1]}
        </TableCell>
        {[30, 40, 50, 51, 52].map((armazem) => (
          <TableCell
            key={armazem}
            style={{
              backgroundColor:
                armazemMap[armazem].qtdContada < 90 ? '#FECACA' : '#BBF7D0',
            }}
          >
            {formatPercentage(armazemMap[armazem].qtdContada)}
          </TableCell>
        ))}
        <TableCell
          style={{
            backgroundColor: acuracidadeGeral < 90 ? '#FECACA' : '#BBF7D0',
          }}
        >
          {mes.acuracidadeGeral}
        </TableCell>
      </TableRow>
    );
  };

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Mês</TableCell>
            <TableCell>ARM 30</TableCell>
            <TableCell>ARM 40</TableCell>
            <TableCell>ARM 50</TableCell>
            <TableCell>ARM 51</TableCell>
            <TableCell>ARM 52</TableCell>
            <TableCell>Realizado Geral</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {meses.length > 0 ? (
            meses.map(renderRows)
          ) : (
            <TableRow>
              <TableCell colSpan={7} align="center">
                Nenhum dado disponível
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default TabelaKpi;

import React, { useContext, lazy, Suspense, useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useNavigate,
} from 'react-router-dom';
import {
  Relatorios,
  RelatoriosUm,
  RelatoriosDois,
  RelatoriosTres,
} from '../pages/Relatorios';
import { isAuthenticated } from '../services/auth';
import { VerificaNotaPortariaProvider } from '../hooks/notas-fiscais/nota-fiscal-verifica-portaria.hook';
import { NotasEmitidasProvider } from '../hooks/notas-fiscais/notas-emitidas.hook';
import { EntregasProvider } from '../hooks/entrega/entregas.hook';
import { Usuario } from '../hooks/usuarios/usuario.hook';
import Loading from '../components/Loading';
import Grafico from '../components/Graficos';
import NotaFiscalTemplate from '../components/TemplatesPdf/NotaFiscal';
import ControleRPCTemplate from '../components/TemplatesPdf/ControleRecebimentoPermanenciaContainer';
import UsuarioBloqueado from '../pages/UsuarioBloqueiado';
import useUsuarioLocal from '../hooks/usuarioLocal.hook';
import Manutencao from '../pages/Manutenção';
import GerenciamentoTi from '../pages/GerenciamentoTi';
import DashaboardTi from '../pages/GerenciamentoTi/Dashboard';
import GeradorQrCode from '../pages/GeradorQrCode';
import EntregasTeste from '../pages/Entregas/novaEntrega';
import { ContratosProvider } from '../hooks/contrato';
import LeitorQRCode from '../pages/LeitorQrCode';
import { Inteligencia } from '../pages/Inteligencia';
import { Catalogo } from '../pages/Catalogo';
import { PainelAeroporto } from '../pages/PainelAeroporto';
import ControleCubagemTemplate from '../components/TemplatesPdf/Cubagem/ControleCubagemTemplate';
import TelaChamados from '../pages/TelaChamadosGeral';
import ChamadosPorSetor from '../pages/TelaChamadosGeral/ChamadosSetor';
import { PlanejamentoProducao } from '../pages/Producoes/PlanejamentoProducao';
import TabelaMestra from '../pages/TabelaMestra';
import XChamados from '../pages/Chamados/Xindex';
import XContato from '../pages/Contato/Xindex';
import ModalCubagem from '../pages/Pedidos/components/modalCubagem';
import ControleEstoqueTi from '../pages/ControleEstoqueTi';
import HistoricoMovimentacaoTi from '../pages/ControleMovimentacaoTI';
import ApontamentosWeb from '../pages/ApontamentosWeb';
import ApontamentoChamados from '../pages/ApontamentoChamados';
import ProdutoForaDeLinha from '../pages/ProdutoForaDeLinha';
import LogsMonitoramento from '../pages/LogsMonitoramento';
import UserMonitoramento from '../pages/LogsMonitoramento/usuarioMonitoramento';
import EstoqueMei from '../pages/EstoqueMei';
import MeiPdf from '../pages/EstoqueMei/components/PdfMei';
import KPIInventario from '../pages/KPIinventario';
import DashboardNotasFiscaisEmitidas from '../pages/NotasFiscaisEmitidas/dashboard';
const Login = lazy(() => import('../pages/Login'));
const Admin = lazy(() => import('../pages/Admin'));
const Chamados = lazy(() => import('../pages/Chamados'));
const ChecagemNotaEstoque = lazy(() => import('../pages/ChecagemNotaEstoque'));
const TitulosPagar = lazy(() => import('../pages/TitulosPagar'));
const TitulosReceber = lazy(() => import('../pages/TitulosReceber'));
const Principal = lazy(() => import('../pages/Principal'));
const ListaDeEquipamentos = lazy(() => import('../pages/ListaDeEquipamentos'));
const DashboardApresentation = lazy(() =>
  import('../pages/DashboardApresentation')
);
const NotasFiscais = lazy(() => import('../pages/NotasFiscais'));
const GerenciadorUser = lazy(() => import('../pages/GerenciadorUser'));
const Faturamento = lazy(() => import('../pages/Faturamento'));
const AdminUser = lazy(() => import('../pages/AdminUser'));
const OrdemServico = lazy(() => import('../pages/OrdemServico'));
const ContratoRMK = lazy(() => import('../pages/ContratosRMK'));
const Qrcode = lazy(() => import('../pages/Qrcode'));
const Thanks = lazy(() => import('../pages/Thanks'));
const Margens = lazy(() => import('../pages/Margens'));
const Estoque = lazy(() => import('../pages/Estoque'));
const VirtualSupply = lazy(() => import('../pages/VirtualSupply'));
const Contato = lazy(() => import('../pages/Contato'));
const Pedidos = lazy(() => import('../pages/Pedidos'));
const Entrega = lazy(() => import('../pages/Entregas'));
const Produtos = lazy(() => import('../pages/Produtos'));
const Inspecao = lazy(() => import('../pages/Inspecao'));
const EntradaDeImportacao = lazy(() => import('../pages/EntradaDeImportacao'));
const Producao = lazy(() => import('../pages/Producoes'));
const NaoLogado = lazy(() => import('../pages/NaoLogado'));
const Dashboard = lazy(() => import('../pages/Dashboard'));
const DashboardNovo = lazy(() => import('../pages/DashboardNovo'));
const VisaoGeral = lazy(() => import('../pages/VisaoGeral'));
const NotasSaida = lazy(() => import('../pages/NotasSaída'));
const Importacao = lazy(() => import('../pages/Importacao'));
const Financeiro = lazy(() => import('../pages/Financeiro'));
const NotaFiscal = lazy(() => import('../pages/NotaFiscal'));
const Apresentacao = lazy(() => import('../pages/Apresentacao'));
const Promotoras = lazy(() => import('../pages/Promotoras'));
const EnviosBahia = lazy(() => import('../pages/EnviosBahia'));
const ClientesComVendedores = lazy(() => import('../pages/ClientesComVendedores'));
const Apontamento = lazy(() => import('../pages/Apontamentos'));
const EficienciaWeb = lazy(() => import('../pages/EficienciaWeb'));
const Fornecedores = lazy(() => import('../pages/Fornecedores'));
const FaturamentoBrasil = lazy(() => import('../pages/FaturamentoBrasil'));
const ExpedicaoLog = lazy(() => import('../pages/ExpedicaoLog'));
const DashProducao = lazy(() => import('../pages/DashProducao'));
const DashComercial = lazy(() => import('../pages/DashComercial'));
const PromotorasCrud = lazy(() => import('../pages/PromotorasCrud'));
const ColetaEEntrega = lazy(() => import('../pages/ColetaEEntrega'));
const ProtocolodeNotas = lazy(() => import('../pages/ProtocolodeNotas'));
const CadastroProtocolodeNotas = lazy(() =>
  import('../pages/ProtocolodeNotas/cadastro')
);
const MovimentacaoCorrente = lazy(() =>
  import('../pages/MovimentacaoCorrente')
);
const MovimentacaoCorrenteMovimentacoes = lazy(() =>
  import('../pages/MovimentacaoCorrente/ConsultaMovimentacao')
);
const RecebimentoContainer = lazy(() =>
  import('../pages/RecebimentoContainer')
);
const Insumos = lazy(() => import('../pages/Insumos'));
const InsumosSaldo = lazy(() => import('../pages/InsumosSaldo'));
const CustodeRetorno = lazy(() => import('../pages/CustodeRetorno'));
const PaginaParaTestes = lazy(() => import('../pages/MargensTeste'));
const RedefinirSenha = lazy(() => import('../pages/RedefinirSenha'));
const NotasFiscaisEmitidas = lazy(() =>
  import('../pages/NotasFiscaisEmitidas')
);
const ColetaEEntregaColeta = lazy(() =>
  import('../pages/ColetaEEntrega/Coleta')
);
const ColetaEEntregaEntrega = lazy(() =>
  import('../pages/ColetaEEntrega/Entrega')
);
const RegistroDeFornecedores = lazy(() =>
  import('../pages/RegistroDeFornecedores')
);
const PrintPage = lazy(() => import('../pages/PrintPage'));
const GerenciamentoInfo = lazy(() => import('../pages/GerenciamentoInfo'));
const InventarioEquipamento = lazy(() =>
  import('../pages/InventarioEquipamento')
);
const HistoricoMovimentacaoEquipamento = lazy(() =>
  import('../pages/InventarioEquipamento/HistoricoMovimentacaoEquipamento')
);
const LogServicos = lazy(() => import('../pages/LogServicos'));
const AprovadorProtocoloDeNotasPagina = lazy(() =>
  import('../pages/AprovadorProtocoloDeNotas')
);
const EntradaProtocoloNotas = lazy(() =>
  import('../pages/EntradaProtocoloNotas')
);
const ExpedicaoLogistica = lazy(() => import('../pages/ExpedicaoLogistica'));
const AgendamentoCarga = lazy(() => import('../pages/AgendamentoCarga'));
const TaxaDeOcupacao = lazy(() => import('../pages/TaxaDeOcupacao'));
const IndicadoresNps = lazy(() => import('../pages/IndicadoresNps'));
const PatchNotes = lazy(() => import('../pages/PatchNotes'));

export default function Rotas() {
  return (
    <Router>
      <Suspense fallback={<Loading />}>
        <Routes>
          <Route path="/" exact element={<Login />} />
          <Route
            path="/visaogeral"
            exact
            element={
              <RequireAuth id={null}>
                <VisaoGeral />
              </RequireAuth>
            }
          />
          {/* Início Rotas Setor Comercial */}
          <Route
            path="/pedidos"
            exact
            element={
              <RequireAuth setorAccess={[1, 2, 7]}>
                <Pedidos />
              </RequireAuth>
            }
          />
          <Route
            path="/notasfiscaisemitidas"
            exact
            element={
              <RequireAuth setorAccess={[1, 3, 2, 7]}>
                <NotasEmitidasProvider>
                  <NotasFiscaisEmitidas />
                </NotasEmitidasProvider>
              </RequireAuth>
            }
          />
          {/* Fim Rotas Setor Comercial */}
          <Route path="/relatorios" exact element={<Relatorios />} />
          <Route
            path="/relatorios/relatorios1"
            exact
            element={
              <RequireAuth id={null}>
                <RelatoriosUm />
              </RequireAuth>
            }
          />
          <Route
            path="/relatorios/relatorios2"
            exact
            element={
              <RequireAuth id={null}>
                <RelatoriosDois />
              </RequireAuth>
            }
          />
          <Route
            path="/relatorios/relatorios3"
            exact
            element={
              <RequireAuth id={null}>
                <RelatoriosTres />
              </RequireAuth>
            }
          />
          <Route
            path="/printpdf/:documento"
            exact
            element={
              <RequireAuth id={null}>
                <PrintPage>
                  <NotaFiscalTemplate />
                </PrintPage>
              </RequireAuth>
            }
          />
          <Route
            path="/printpdfmei"
            exact
            element={
              <RequireAuth id={null}>
                <PrintPage>
                  <MeiPdf />
                </PrintPage>
              </RequireAuth>
            }
          />
          <Route
            path="/printrpc/:documento"
            exact
            element={
              <RequireAuth id={null}>
                <PrintPage>
                  <ControleRPCTemplate />
                </PrintPage>
              </RequireAuth>
            }
          />
          <Route
            path="/printcubagem/:documento"
            exact
            element={
              <RequireAuth id={null}>
                <PrintPage>
                  <ControleCubagemTemplate />
                </PrintPage>
              </RequireAuth>
            }
          />
          <Route
            path="/ModalCubagem"
            exact
            element={
              <RequireAuth id={null}>
                <PrintPage>
                  <ModalCubagem />
                </PrintPage>
              </RequireAuth>
            }
          />
          <Route
            path="/produtos"
            exact
            element={
              <RequireAuth id={3} nivelAccess={[1, 2, 3, 4, 5, 6, 7, 8, 9]}>
                <Produtos />
              </RequireAuth>
            }
          />
          <Route
            path="/graficos"
            exact
            element={
              <RequireAuth id={null}>
                <Grafico />
              </RequireAuth>
            }
          />
          <Route
            path="/geradorqrcode"
            exact
            element={
              <RequireAuth id={null}>
                <GeradorQrCode />
              </RequireAuth>
            }
          />
          <Route
            path="/clientesComVendedores"
            exact
            element={
              <RequireAuth id={37}>
                <ClientesComVendedores />
              </RequireAuth>
            }
          />
          <Route
            path="/qrcode"
            exact
            element={
              <RequireAuth id={null}>
                <Qrcode />
              </RequireAuth>
            }
          />
          <Route
            path="/apontamentos"
            exact
            element={
              <RequireAuth id={4}>
                <Apontamento />
              </RequireAuth>
            }
          />
          <Route
            path="/eficienciaWeb"
            exact
            element={
              <RequireAuth id={4}>
                <EficienciaWeb />
              </RequireAuth>
            }
          />
          <Route
            path="/checagemnotaestoque"
            exact
            element={
              <RequireAuth id={null}>
                <ChecagemNotaEstoque />
              </RequireAuth>
            }
          />
          <Route
            path="/estoqueMei"
            exact
            element={
              <RequireAuth id={null}>
                <EstoqueMei />
              </RequireAuth>
            }
          />
          <Route
            path="/inventariokpi"
            exact
            element={
              <RequireAuth id={null}>
                <KPIInventario />
              </RequireAuth>
            }
          />
          <Route
            path="inteligencia/ordemservicos"
            exact
            element={
              <RequireAuth id={5}>
                <OrdemServico />
              </RequireAuth>
            }
          />
          <Route
            path="inteligencia/apontamentosWeb"
            exact
            element={
              <RequireAuth id={5}>
                <ApontamentosWeb />
              </RequireAuth>
            }
          />
          <Route
            path="inteligencia/controleEstoqueTi"
            exact
            element={
              <RequireAuth id={5}>
                <ControleEstoqueTi />
              </RequireAuth>
            }
          />
          <Route
            path="inteligencia/monitoramento"
            exact
            element={
              <RequireAuth id={5}>
                <LogsMonitoramento />
              </RequireAuth>
            }
          />
          <Route
            path="inteligencia/usermonitoramento"
            exact
            element={
              <RequireAuth id={5}>
                <UserMonitoramento />
              </RequireAuth>
            }
          />
          <Route
            path="/margens"
            exact
            element={
              <RequireAuth id={6} nivelAccess={[6, 7, 8]}>
                <Margens />
              </RequireAuth>
            }
          />
          <Route
            path="/entregas"
            exact
            element={
              <RequireAuth id={2} setorAccess={[1, 2, 9, 8, 15, 7, 11]}>
                <EntregasProvider>
                  <Entrega />
                </EntregasProvider>
              </RequireAuth>
            }
          />
          <Route
            path="/paginatest"
            exact
            element={
              <RequireAuth id={2} setorAccess={[1, 2, 8, 7, 9, 15]}>
                <EntregasProvider>
                  <EntregasTeste />
                </EntregasProvider>
              </RequireAuth>
            }
          />
          <Route
            path="/estoque"
            exact
            element={
              <RequireAuth id={8}>
                <Estoque />
              </RequireAuth>
            }
          />
          <Route />
          <Route
            path="/producoes"
            exact
            element={
              <RequireAuth id={10}>
                <Producao />
              </RequireAuth>
            }
          />
          <Route
            path="/producoes"
            exact
            element={
              <RequireAuth id={10}>
                <Producao />
              </RequireAuth>
            }
          />
          <Route
            path="/dashboard"
            exact
            element={
              <RequireAuth id={1}>
                <DashboardNovo />
              </RequireAuth>
            }
          />
          <Route
            path="/promotoras"
            exact
            element={
              <RequireAuth id={11}>
                <Promotoras />
              </RequireAuth>
            }
          />
          <Route
            path="/promotorascrud"
            exact
            element={
              <RequireAuth id={12}>
                <PromotorasCrud />
              </RequireAuth>
            }
          />
          <Route
            path="/faturamento"
            exact
            element={
              <RequireAuth id={13}>
                <Faturamento />
              </RequireAuth>
            }
          />
          <Route
            path="/expedicaolog"
            exact
            element={
              <RequireAuth id={14}>
                <ExpedicaoLog />
              </RequireAuth>
            }
          />
          <Route
            path="/virtualsupply"
            exact
            element={
              <RequireAuth id={15}>
                <VirtualSupply />
              </RequireAuth>
            }
          />
          <Route
            path="/dashboardapresentation"
            exact
            element={
              <RequireAuth id={16}>
                <DashboardApresentation />
              </RequireAuth>
            }
          />
          <Route
            path="/import_c7_entrada"
            exact
            element={
              <RequireAuth id={17}>
                <EntradaDeImportacao />
              </RequireAuth>
            }
          />
          <Route
            path="inteligencia/admin"
            exact
            element={
              <RequireAuth id={18}>
                <Admin />
              </RequireAuth>
            }
          />
          <Route
            path="/notafiscal/:documento"
            exact
            element={
              <RequireAuth id={19}>
                <NotaFiscal />
              </RequireAuth>
            }
          />
          <Route
            path="/fiscal"
            exact
            element={
              <RequireAuth id={19}>
                <NotasFiscais />
              </RequireAuth>
            }
          />
          <Route
            path="/dashproducao"
            exact
            element={
              <RequireAuth id={20}>
                <DashProducao />
              </RequireAuth>
            }
          />
          <Route
            path="/dashcomercial"
            exact
            element={
              <RequireAuth id={21}>
                <DashComercial />
              </RequireAuth>
            }
          />
          <Route
            path="/inteligencia/listadeequipamentos"
            exact
            element={
              <RequireAuth id={22}>
                <ListaDeEquipamentos />
              </RequireAuth>
            }
          />
          <Route
            path="/contato"
            exact
            element={
              <RequireAuth id={23}>
                <Contato />
              </RequireAuth>
            }
          />
          <Route
            path="/telachamados"
            exact
            element={
              <RequireAuth id={23}>
                <TelaChamados />
              </RequireAuth>
            }
          />
          <Route
            path="/chamadosporsetor"
            exact
            element={
              <RequireAuth id={23}>
                <ChamadosPorSetor />
              </RequireAuth>
            }
          />
          <Route
            path="/importacao"
            element={
              <RequireAuth id={24}>
                <Importacao />
              </RequireAuth>
            }
          />
          <Route
            path="/faturamentobrasil"
            exact
            element={
              <RequireAuth id={25}>
                <FaturamentoBrasil />
              </RequireAuth>
            }
          />
          <Route
            path="/financeiro"
            exact
            element={
              <RequireAuth id={26} setorAccess={[2, 4, 7, 15]}>
                <Financeiro />
              </RequireAuth>
            }
          />
          <Route
            path="/inspecao"
            exact
            element={
              <RequireAuth id={27}>
                <Inspecao />
              </RequireAuth>
            }
          />
          <Route
            path="/enviosbahia"
            exact
            element={
              <RequireAuth id={null}>
                <EnviosBahia />
              </RequireAuth>
            }
          />
          <Route
            path="/fornecedores"
            exact
            element={
              <RequireAuth id={28} nivelAccess={[6, 7, 8]}>
                <Fornecedores />
              </RequireAuth>
            }
          />
          <Route
            path="/registrofornecedoreschina"
            exact
            element={
              <RequireAuth id={29} nivelAccess={[6, 7, 8]}>
                <RegistroDeFornecedores />
              </RequireAuth>
            }
          />
          <Route
            path="/contratos"
            exact
            element={
              <RequireAuth id={30}>
                <ContratosProvider>
                  <ContratoRMK />
                </ContratosProvider>
              </RequireAuth>
            }
          />
          <Route
            path="/protocolodenotas"
            exact
            element={
              <RequireAuth id={31}>
                <ProtocolodeNotas />
              </RequireAuth>
            }
          />
          <Route
            path="/cadastroprotocolodenotas"
            exact
            element={
              <RequireAuth id={31}>
                <CadastroProtocolodeNotas />
              </RequireAuth>
            }
          />
          <Route
            path="/aprovadorprotocolodenotas"
            exact
            element={
              <RequireAuth id={31}>
                <AprovadorProtocoloDeNotasPagina />
              </RequireAuth>
            }
          />
          <Route
            path="/notassaida"
            exact
            element={
              <RequireAuth id={32} setorAccess={[0, 2, 7, 13, 15]}>
                <VerificaNotaPortariaProvider>
                  <NotasSaida />
                </VerificaNotaPortariaProvider>
              </RequireAuth>
            }
          />
          <Route
            path="/thanks"
            exact
            element={
              <RequireAuth id={null}>
                <Thanks />
              </RequireAuth>
            }
          />
          <Route
            path="/coletaeentrega"
            exact
            element={
              <RequireAuth id={34}>
                <ColetaEEntrega />
              </RequireAuth>
            }
          />

          <Route
            path="/coletaeentrega/coleta"
            exact
            element={
              <RequireAuth id={35}>
                <ColetaEEntregaColeta />
              </RequireAuth>
            }
          />
          <Route
            path="/coletaeentrega/entrega"
            exact
            element={
              <RequireAuth id={36}>
                <ColetaEEntregaEntrega />
              </RequireAuth>
            }
          />
          <Route
            path="/apresentacao"
            exact
            element={
              <RequireAuth id={51}>
                <Apresentacao />
              </RequireAuth>
            }
          />

          <Route
            path="/titulospagar"
            exact
            element={
              <RequireAuth id={38}>
                <TitulosPagar />
              </RequireAuth>
            }
          />
          <Route
            path="/titulosreceber"
            exact
            element={
              <RequireAuth id={39}>
                <TitulosReceber />
              </RequireAuth>
            }
          />
          <Route
            path="/movimentacaocorrente"
            exact
            element={
              <RequireAuth id={40}>
                <MovimentacaoCorrente />
              </RequireAuth>
            }
          />
          <Route
            path="/movimentacaocorrente/movimentacoes"
            exact
            element={
              <RequireAuth id={41}>
                <MovimentacaoCorrenteMovimentacoes />
              </RequireAuth>
            }
          />
          <Route
            path="/recebimentopermanenciacontainer"
            exact
            element={
              <RequireAuth id={42}>
                <RecebimentoContainer />
              </RequireAuth>
            }
          />
          <Route
            path="/insumosmov"
            exact
            element={
              <RequireAuth id={43}>
                <Insumos />
              </RequireAuth>
            }
          />
          <Route
            path="/inteligencia/movimentacaoTi"
            exact
            element={
              <RequireAuth id={43}>
                <HistoricoMovimentacaoTi />
              </RequireAuth>
            }
          />
          <Route
            path="/insumossaldo"
            exact
            element={
              <RequireAuth id={44}>
                <InsumosSaldo />
              </RequireAuth>
            }
          />
          <Route
            path="inteligencia/gerenciamentoinfo"
            exact
            element={
              <RequireAuth id={45}>
                <GerenciamentoInfo />
              </RequireAuth>
            }
          />
          <Route
            path="/custoderetorno"
            exact
            element={
              <RequireAuth id={46}>
                <CustodeRetorno />
              </RequireAuth>
            }
          />
          <Route
            path="/redefinirsenha"
            exact
            element={
              <RequireAuth id={47}>
                <RedefinirSenha />
              </RequireAuth>
            }
          />
          <Route
            path="inteligencia"
            exact
            element={
              <RequireAuth id={null}>
                <Inteligencia />
              </RequireAuth>
            }
          />
          <Route
            path="patchNotes"
            exact
            element={
              <RequireAuth id={null}>
                <PatchNotes />
              </RequireAuth>
            }
          />
          <Route
            path="catalogo"
            exact
            element={
              <RequireAuth id={null}>
                <Catalogo />
              </RequireAuth>
            }
          />
          <Route
            path="painelAeroporto"
            exact
            element={
              <RequireAuth id={null}>
                <PainelAeroporto />
              </RequireAuth>
            }
          />
          <Route
            path="/admin/gerenciadoruser"
            exact
            element={
              <RequireAuth id={48}>
                <GerenciadorUser />
              </RequireAuth>
            }
          />
          <Route
            path="/adminuser"
            exact
            element={
              <RequireAuth id={49}>
                <AdminUser />
              </RequireAuth>
            }
          />
          <Route
            path="inteligencia/gerenciamentoti"
            exact
            element={
              <RequireAuth id={50}>
                <GerenciamentoTi />
              </RequireAuth>
            }
          />
          <Route
            path="/gerenciamentoti/dashboard"
            exact
            element={
              <RequireAuth id={50}>
                <DashaboardTi />
              </RequireAuth>
            }
          />
          <Route
            path="inteligencia/gerenciamentodechamado"
            exact
            element={
              <RequireAuth id={51}>
                <Chamados />
              </RequireAuth>
            }
          />
          <Route
            path="/gerenciamentodechamado"
            exact
            element={
              <RequireAuth id={51}>
                <Chamados />
              </RequireAuth>
            }
          />
          <Route
            path="inteligencia/inventario"
            exact
            element={
              <RequireAuth id={60}>
                <InventarioEquipamento />
              </RequireAuth>
            }
          />
          <Route
            path="/inventario/historicoequipamento"
            exact
            element={
              <RequireAuth id={60}>
                <HistoricoMovimentacaoEquipamento />
              </RequireAuth>
            }
          />
          <Route
            path="/expedicaologistica"
            exact
            element={
              <RequireAuth id={null}>
                <ExpedicaoLogistica />
              </RequireAuth>
            }
          />
          <Route
            path="/agendamentocarga"
            exact
            element={
              <RequireAuth id={null}>
                <AgendamentoCarga />
              </RequireAuth>
            }
          />
          <Route
            path="/taxadeocupacao"
            exact
            element={
              <RequireAuth id={null}>
                <TaxaDeOcupacao />
              </RequireAuth>
            }
          />
          <Route
            path="/kpisposvenda"
            exact
            element={
              <RequireAuth id={null}>
                <IndicadoresNps />
              </RequireAuth>
            }
          />
          <Route
            path="/planejamentoproducao"
            exact
            element={
              <RequireAuth id={null}>
                <PlanejamentoProducao />
              </RequireAuth>
            }
          />
          <Route
            path="/tabelaMestra"
            exact
            element={
              <RequireAuth id={null}>
                <TabelaMestra />
              </RequireAuth>
            }
          />
          <Route
            path="inteligencia/logservicos"
            exact
            element={
              <RequireAuth id={null}>
                <LogServicos />
              </RequireAuth>
            }
          />
          <Route
            path="inteligencia/apontamentochamados"
            exact
            element={
              <RequireAuth id={null}>
                <ApontamentoChamados />
              </RequireAuth>
            }
          />
          <Route
            path="/principal"
            exact
            element={
              <RequireAuth id={null}>
                <Principal />
              </RequireAuth>
            }
          />
          <Route
            path="/manutencao"
            exact
            element={
              <RequireAuth id={null}>
                <Manutencao />
              </RequireAuth>
            }
          />
          <Route
            path="/registroqrcode"
            exact
            element={
              <RequireAuth id={null}>
                <LeitorQRCode />
              </RequireAuth>
            }
          />
          <Route
            path="/entradaprotocolonotas"
            exact
            element={
              <RequireAuth id={null}>
                <EntradaProtocoloNotas />
              </RequireAuth>
            }
          />
          <Route
            path="/xchamados"
            exact
            element={
              <RequireAuth id={null}>
                <XChamados />
              </RequireAuth>
            }
          />
          <Route
            path="inteligencia/xchamados"
            exact
            element={
              <RequireAuth id={null}>
                <XChamados />
              </RequireAuth>
            }
          />
          <Route
            path="/xcontato"
            exact
            element={
              <RequireAuth id={null}>
                <XContato />
              </RequireAuth>
            }
          />
          <Route
            path="/historicodoproduto"
            exact
            element={
              <RequireAuth id={null}>
                <ProdutoForaDeLinha />
              </RequireAuth>
            }
          />
          <Route
            path="/dashboardnotasfiscais"
            exact
            element={
              <RequireAuth id={null}>
                <DashboardNotasFiscaisEmitidas />
              </RequireAuth>
            }
          />

          <Route path="/401" exact element={<NaoLogado />} />
          <Route path="/noaccess" exact element={<UsuarioBloqueado />} />
        </Routes>
      </Suspense>
    </Router>
  );
}

function RequireAuth({
  children,
  setorAccess = [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
  ],
  nivelAccess = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
}) {
  const { loading } = useContext(Usuario);
  const [statusPage, setStatusPage] = useState([]);
  const { setor, nivel } = useUsuarioLocal();
  const navigateHook = useNavigate();

  useEffect(() => {
    if (statusPage.status === 0) {
      navigateHook('/manutencao');
    }
    if (statusPage === '') {
      return children;
    }
  }, [statusPage]);

  if (loading) {
    return <Loading />;
  }

  const setorPermitido = setorAccess.includes(setor);

  if (!setorPermitido) {
    return <Navigate to="/401" />;
  }

  if (!isAuthenticated()) {
    return <Navigate to="/401" />;
  }
  return children;
}
